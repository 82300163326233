/* eslint-disable linebreak-style */
import React, { useRef } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import Navbar from './Navbar';

export default function Header(props) {
    const { location, children } = props;
    const containerRef = useRef();
    const overlayRef = useRef();
    const hamburgerRef = useRef();    

    function toggleMenu() {
        const container = containerRef?.current;
        const overlay = overlayRef?.current;
        const hamburger = hamburgerRef?.current;

        if (!container.classList.contains('active')) {
            container.style.top = '-223.33px';
            setTimeout(() => { container.style.top = '' }, 0);
        }

        container?.classList.toggle('active');
        overlay?.classList.toggle('active');
        hamburger?.classList.toggle('opened');
        hamburger?.setAttribute('aria-expanded', hamburger?.classList.contains('opened'));
    }

    return (
        <>
            <header className="header header--cover header__bg">
                <section className="header__header container" style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Link to="/">
                        <StaticImage
                            src="../assets/logo-lg.png"
                            alt="logo"
                            placeholder="tracedSVG"
                            loading="eager"
                            width={400}
                            height={205.44} />
                    </Link>
                </section>
                <div id="navbar--container">
                    <Navbar ref={containerRef} hamRef={hamburgerRef} onToggle={toggleMenu} location={location} />
                </div>
                {children}
            </header>
            <div ref={overlayRef} onClick={toggleMenu} id="overlay" />
        </>
    );
}
