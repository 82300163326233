/* eslint-disable max-len */
import React from 'react';
import { /*useStaticQuery, graphql, */Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import Categories from './Categories';

export default function Footer(props) {  
  /*const { allWpMenuItem } = useStaticQuery(graphql`
    {
      allWpMenuItem(
        filter: {locations: {eq: GATSBY_FOOTER_MENU}}
        sort: {order: ASC, fields: order}
      ) {
        nodes {
          id
          label
          path
        }
      }
    }
  `);
  */
  const allWpMenuItem = {
    nodes: [
      {
        id: 'privacy',
        label: 'Datenschutz',
        path: '/datenschutz',
      },
      {
        id: 'legal',
        label: 'Impressum',
        path: '/impressum',
      },
    ]  
  };
  const date = new Date();

  return (
    <footer style={{minHeight: '150px', padding: '1.5em 0 .5em'}}>      
      <div className="container container--wide js-blog-posts">   
        <Categories { ...props } />
        <div>
  		    <a href="https://blogfamilia.de/blogfamilia-award-2019-herzlichen-glueckwunsch-das-sind-unsere-preistraegerinnen" 
            rel="noreferrer" target="_blank"
          >			
      			<StaticImage
      			   src="../assets/Award_farbig_ohnehintergrund.png"
      			   alt="Blogfamilia Award 2019"
      			   placeholder="tracedSVG"
      			   loading="lazy"
               formats={['PNG']}
      			   width={350}
      			   height={127.73} /> 
            </a>
        </div>
        <span style={{float: 'left', paddingTop: '.8333335rem'}}>
          &copy; 2014 - {date.getFullYear()} xmalanderssein.de
        </span>
        <div style={{float: 'right'}}>
          {allWpMenuItem.nodes.map((item, i) => {
            if (item.external) {
              return (
                <a key={item.id} href={item.path} className="header__tab__link fading">
                  {item.node.label}
                </a>
              );
            }
            return (
              <>          
               <Link key={item.id} to={item.path} className="header__tab__link fading" style={{ paddingBottom: 0 }}>
                  {item.label}
                </Link>              
                { (i + 1 < allWpMenuItem.nodes.length) ? ' | ' : null }
              </>
            );
          })}
        </div>
      </div>
    </footer>
  );
};
