import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

import '../assets/css/search.css';

export default function SearchButton(props) {
  const { onClick, width, height } = props;
  const buttonRef = useRef();
  const inputRef = useRef();
  const [state, setState] = useState({
    active: false,
  });

  const style = {    
    width,
    height,
  };

  const handleClick = () => {
    const active = !state.active;
      
    if (active) {
      setTimeout(() => inputRef.current.focus(), 300);
      setState({ ...state, active });
    } else {
      inputRef.current.value = '';
      setState({ active });
    } 
  };

  const handleChange = async (e) => {
    const query = encodeURI(e.target.value);

    if (query.length > 2) {
      try {
        const res = await fetch(`https://xmalanderssein.de/wp-json/wp/v2/search/?search=${query}`);

        if (res.ok) {
          const result = await res.json();
          setState({ ...state, result, error: undefined });
        } else {
          setState({ ...state, error });
        }
      } catch(error) {
        setState({ ...state, error });
      }
    } else {
      setState({ ...state, result: undefined });
    }
  };

  const SearchItem = ({ key, title, url }) => (
    <li key={key}>
      <Link to={url.replace('%e2%99%a1', '')} dangerouslySetInnerHTML={{ __html: title }} />
    </li>
  );

  return (
    <div className={`search ${state.active ? 'active' : ''}`}>    
      <div className="wrapper"></div>
      <div className="input-container">
        <input ref={inputRef} onChange={handleChange} type="text" id="query" placeholder="Finden . . ." />
      </div>
      <button onClick={handleClick} className={`menu ${state.active ? 'active' : ''}`} style={style}>
        <FontAwesomeIcon icon="search" />
      </button>
      <div class="result-container">
        <ul>
        {
          state.result && 
            state.result.map(
              ({ title, url }, i) => <SearchItem key={`item-${i+1}`} title={title} url={url} />
            )
        }
        </ul>
      </div>
    </div>
  );
}