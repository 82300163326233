import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,
};
  
function getAnimationSettings(angle, originX) {
    return {
        particleCount: 7,
        angle,
        spread: 55,
        origin: { x: originX, y: 0.5 },
    };
}

export default function Confetti() {    
    const refAnimationInstance = useRef(null);
    const [intervalId, setIntervalId] = useState();
  
    const getInstance = useCallback((instance) => {
      refAnimationInstance.current = instance;
    }, []);
  
    const nextTickAnimation = useCallback(() => {
      if (refAnimationInstance.current) {
        try {
            refAnimationInstance.current(getAnimationSettings(60, 0));
            refAnimationInstance.current(getAnimationSettings(120, 1));
        } catch (e) {
            stopAnimation();
        }
      }
    }, []);
  
    const startAnimation = useCallback(() => {
      if (!intervalId) {
        setIntervalId(setInterval(nextTickAnimation, 32));
      }
    }, [nextTickAnimation, intervalId]);
  
    const pauseAnimation = useCallback(() => {
      clearInterval(intervalId);
      setIntervalId(null);
    }, [intervalId]);
  
    const stopAnimation = useCallback(() => {
      clearInterval(intervalId);
      setIntervalId(null);
      refAnimationInstance.current && refAnimationInstance.current.reset();
    }, [intervalId]);
  
    useEffect(() => {
      return () => {
        clearInterval(intervalId);
      };
    }, [intervalId]);

    useEffect(() => {
        window.addEventListener('touchstart', startAnimation, {passive: true});
        window.addEventListener('touchend', pauseAnimation, {passive: true});
        window.addEventListener('mousedown', startAnimation, {passive: true});
        window.addEventListener('mouseup', pauseAnimation, {passive: true});

        return () => {
            window.removeEventListener('touchstart', startAnimation);
            window.removeEventListener('touchend', pauseAnimation);
            window.removeEventListener('mousedown', startAnimation);
            window.removeEventListener('mouseup', pauseAnimation);
        };
    });

    return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
}