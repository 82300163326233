import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faFacebookF, 
  faTwitter, 
  faInstagram, 
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import { 
  faCheckCircle, 
  faReply, 
  faPaperPlane, 
  faTimes, 
  faExclamationCircle,
  faExclamationTriangle,
  faFolderOpen,
  faTags,
  faComments,
  faFileAlt,
  faAtom,
  faStop,
  faPlay,
  faSearch,  
  faSquare,
  faFile,
  faAlignLeft,
} from '@fortawesome/free-solid-svg-icons';
import './Confetti';

import '../assets/css/main.css';
import '../assets/css/comments.css';
import '../assets/css/categories.css';
import '../assets/css/instafeed.css';
import Confetti from './Confetti';

library.add(
  faFacebookF, 
  faTwitter, 
  faInstagram, 
  faInstagramSquare,
  faCheckCircle, 
  faReply, 
  faPaperPlane, 
  faTimes, 
  faExclamationCircle,
  faExclamationTriangle,
  faFolderOpen,
  faTags,
  faComments,
  faFileAlt,
  faAtom,
  faPlay,
  faStop,
  faSearch,
  faFile,
  faSquare,
  faAlignLeft,
);

export default function Layout(props) {  
  const [showConfetti, setShowConfetti] = useState();
  const { children } = props;

  useEffect(() => {
    	if (showConfetti === undefined) {
        setShowConfetti(
          window?.confetti || 
          window?.today?.day === 10 && 
          window?.today?.month === 11 
        );
      }
  }, [showConfetti]);

  return (
    <div className="layout">
      <Helmet defaultTitle="X Mal anders sein" />
      {children}      
    	{showConfetti && <Confetti />}
    </div>
  );
  
}
