import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';

const Filter = props => {
  const { topic, uri } = props;

  return (
    <Link to={uri}>
      {topic}
    </Link>
  );
};

const Categories = props => {
  const { categories, width } = props;
 
  return (
    <nav id="categories" className="post-preview"  style={{boxShadow: 'none', maxWidth: width ?? ''}}>      
      <header>        
        <h3><FontAwesomeIcon icon="folder-open" style={{marginRight: '.5em'}} />Kategorien</h3>        
      </header>   
      <section>        
        <span><Link to="/">Alle</Link></span>
        {categories?.map(({ name, uri }, index) => (
            <span>
              <Filter key={index} topic={name} uri={uri} />
            </span>
        ))}       
      </section>
      <hr noshade/>
    </nav>
  );
};

export default Categories;
