/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HamburgerButton from './HamburgerButton';
import Search from './Search';
import icon from '../assets/icon@32.png';

const Navbar = React.forwardRef((props, ref) => {
    const { location, onToggle, hamRef } = props;
    const allWpMenuItem = {
        nodes: [
            {
                id: 'home',
                label: 'Blog',
                path: '/',
            },
            {
                id: 'about',
                label: 'Vorstellung',
                path: '/vorstellung',
            },
            {
                id: 'uts',
                label: 'Ullrich-Turner-Syndrom',
                path: '/ullrich-turner-syndrom',
            },
            {
                id: 'book',
                label: 'Das-Buch',
                path: '/das-buch',
            },
        ]
    };

    return (
        <section id="navbar" ref={ref} className="header__tabs-wrap">
            <nav className="container container--wide">
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Link
                            to="/"
                            style={{ display: 'flex', maxWidth: 300 }}>
                            <img id="logo" src={icon} alt="logo" style={
                                {
                                    transition: 'all .2s ease-in',
                                    marginRight: '.75em',
                                    display: 'inline-block',
                                    alignSelf: 'center',
                                    height: '32px',
                                    width: '32px',
                                }
                            } />
                        </Link>
                        <Search width="48px" height="48px" />
                    </div>
                    {allWpMenuItem.nodes.map(item => {
                        if (item.external) {
                            return (
                                <a key={item.id} href={item.path} className="header__item__link hide-mobile">
                                    {item.label}
                                </a>
                            );
                        }
                        return (
                            <Link
                                key={item.id}
                                to={item.path}
                                partiallyActive={item.path === location}
                                activeClassName="active"
                                className="header__item__link hide-mobile">
                                {item.label}
                            </Link>
                        );
                    })}
                    <div id="social-nav">
                        <a className="faded" style={{ color: 'var(--primary-text)' }} href="https://facebook.com/xmalanderssein" target="_blank" rel="noreferrer" external="true">
                            <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" />
                        </a>
                        <a className="faded" style={{ color: 'var(--primary-text)' }} href="https://www.instagram.com/wunderfunken/" target="_blank" rel="noreferrer" external="true" >
                            <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />
                        </a>
                        <a className="faded" style={{ color: 'var(--primary-text)' }} href="https://www.instagram.com/xmalanderssein/" target="_blank" rel="noreferrer" external="true" >
                            <FontAwesomeIcon icon={['fab', 'instagram']} size="lg" />
                        </a>
                    </div>
                    <div className="hamburger--container">
                        <HamburgerButton ref={hamRef} onClick={onToggle} width="48px" height="48px" />
                    </div>
                </div>
            </nav>
        </section>
    );
});

export default Navbar;
